import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaCrown } from 'react-icons/fa';
import backgroundImage from './assets/images/background.jpg';
import { parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Editor } from '@tinymce/tinymce-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ea864d;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin-top: 100px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-top: 50px;
  }
`;

const WelcomeText = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ea864d;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const DateTime = styled.div`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #ea864d;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MenuItem = styled.div`
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  text-align: center;

  &:hover {
    transform: scale(1.05);
    background-color: #333;
  }

  @media (max-width: 768px) {
    padding: 10px;
    margin: 5px;
  }
`;

const Section = styled.div`
  width: 90%;
  max-width: 1200px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  display: ${({ active }) => (active ? 'block' : 'none')};

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  table, th, td {
    border: 1px solid #444;
  }

  th, td {
    padding: 8px;
    text-align: left;

    @media (max-width: 768px) {
      padding: 4px;
    }
  }

  th {
    background-color: #2c2c2c;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin: 0 4px;
  background-color: #2c2c2c;
  color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }

  @media (max-width: 768px) {
    padding: 4px 8px;
    margin: 0 2px;
  }
`;

const FilterForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Input = styled.input`
  padding: 8px;
  margin: 0 8px;
  border-radius: 4px;
  border: 1px solid #444;
  background-color: #2c2c2c;
  color: #fff;

  @media (max-width: 768px) {
    margin: 5px 0;
    padding: 4px;
  }
`;

const EditableCell = styled.td`
  padding: 8px;
  text-align: left;
  border: 1px solid #444;
  background-color: #1e1e1e;

  &:hover {
    background-color: #333;
  }

  @media (max-width: 768px) {
    padding: 4px;
  }
`;

const Admin = () => {
  const navigate = useNavigate();
  const [serverInfo, setServerInfo] = useState({});
  const [admins, setAdmins] = useState([]);
  const [players, setPlayers] = useState([]);
  const [currentPlayers, setCurrentPlayers] = useState([]);
  const [logs, setLogs] = useState([]);
  const [logType, setLogType] = useState('ConnectLog');
  const [logSearch, setLogSearch] = useState('');
  const [logDateFrom, setLogDateFrom] = useState('');
  const [logDateTo, setLogDateTo] = useState('');
  const [logPage, setLogPage] = useState(1);
  const [logTotalPages, setLogTotalPages] = useState(1);
  const [portalLogs, setPortalLogs] = useState([]);
  const [portalLogSearch, setPortalLogSearch] = useState('');
  const [portalLogDateFrom, setPortalLogDateFrom] = useState('');
  const [portalLogDateTo, setPortalLogDateTo] = useState('');
  const [portalLogPage, setPortalLogPage] = useState(1);
  const [portalLogTotalPages, setPortalLogTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState(null);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isAdminLevelSufficient, setIsAdminLevelSufficient] = useState(false);
  const [labels, setLabels] = useState([]);
  const [labelSearch, setLabelSearch] = useState('');
  const [vehicleSearch, setVehicleSearch] = useState('');
  const [labelPage, setLabelPage] = useState(1);
  const [labelTotalPages, setLabelTotalPages] = useState(1);
  const [activeSubTab, setActiveSubTab] = useState('add-label');
  const [username, setUsername] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState(new Date().toLocaleString('ru-RU'));
  const [banLogs, setBanLogs] = useState([]);
  const [banLogSearch, setBanLogSearch] = useState('');
  const [banLogPage, setBanLogPage] = useState(1);
  const [banLogTotalPages, setBanLogTotalPages] = useState(1);
  const [parkCarLogs, setParkCarLogs] = useState([]);
  const [parkCarLogSearch, setParkCarLogSearch] = useState('');
  const [parkCarLogPage, setParkCarLogPage] = useState(1);
  const [parkCarLogTotalPages, setParkCarLogTotalPages] = useState(1);
  const [banLogDateFrom, setBanLogDateFrom] = useState('');
  const [banLogDateTo, setBanLogDateTo] = useState('');
  const [banLogTimeFrom, setBanLogTimeFrom] = useState('');
  const [banLogTimeTo, setBanLogTimeTo] = useState('');
  const [parkCarLogDateFrom, setParkCarLogDateFrom] = useState('');
  const [parkCarLogDateTo, setParkCarLogDateTo] = useState('');
  const [parkCarLogTimeFrom, setParkCarLogTimeFrom] = useState('');
  const [parkCarLogTimeTo, setParkCarLogTimeTo] = useState('');
  const [adminRules, setAdminRules] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const formatDate = (dateString) => {
    // Парсинг даты в формате "dd/MM/yyyy"
    const parsedDate = parse(dateString, 'd/M/yyyy', new Date(), { locale: ru });
    return parsedDate.toLocaleDateString('ru-RU');
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date().toLocaleString('ru-RU'));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const fetchAdminRules = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin-rules`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setAdminRules(response.data.rules);
    } catch (error) {
      console.error('Error fetching admin rules:', error);
    }
  }, []);
  
  const saveAdminRules = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/save-admin-rules`, {
        rules: adminRules
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving admin rules:', error);
    }
  };  

  const logAction = async (action, details) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/log-action`, {
        action,
        details
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error logging action:', error);
    }
  };

  const fetchPlayers = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/players`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          search,
          limit: 10,
          offset: (page - 1) * 10
        }
      });
      setPlayers(response.data.players);
      setTotalPages(Math.ceil(response.data.totalCount / 10));
      logAction('Fetch Players', `Searched players with keyword: ${search}`);
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  }, [page, search]);

  const fetchAdminInfo = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin-info`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          dateFrom,
          dateTo
        }
      });
      setAdmins(response.data);
      logAction('Fetch Admin Info', `Filtered admin info from ${dateFrom || 'N/A'} to ${dateTo || 'N/A'}`);
    } catch (error) {
      console.error('Error fetching admin info:', error);
    }
  }, [dateFrom, dateTo]);

  const fetchLogs = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/logs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          logType,
          logSearch,
          logDateFrom,
          logDateTo,
          limit: 20,
          offset: (logPage - 1) * 20
        }
      });
      setLogs(response.data.logs);
      setLogTotalPages(Math.ceil(response.data.totalCount / 20));
      logAction('Fetch Logs', `Filtered logs of type ${logType} from ${logDateFrom || 'N/A'} to ${logDateTo || 'N/A'} with search keyword: ${logSearch}`);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  }, [logType, logSearch, logDateFrom, logDateTo, logPage]);

  const fetchPortalLogs = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/portal-logs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          portalLogSearch,
          portalLogDateFrom,
          portalLogDateTo,
          limit: 20,
          offset: (portalLogPage - 1) * 20
        }
      });
      setPortalLogs(response.data.logs);
      setPortalLogTotalPages(Math.ceil(response.data.totalCount / 20));
    } catch (error) {
      console.error('Error fetching portal logs:', error);
    }
  }, [portalLogSearch, portalLogDateFrom, portalLogDateTo, portalLogPage]);

  const fetchLabels = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/labels`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          search: labelSearch,
          vehicleId: vehicleSearch,
          limit: 10,
          offset: (labelPage - 1) * 10
        }
      });
      setLabels(response.data.labels);
      setLabelTotalPages(Math.ceil(response.data.totalCount / 10));
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  }, [labelSearch, vehicleSearch, labelPage]);

  const fetchCurrentPlayers = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/current-players`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCurrentPlayers(response.data);
    } catch (error) {
      console.error('Error fetching current players:', error);
    }
  }, []);

  const fetchAdminData = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin-data`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUsername(response.data.username); // Убедитесь, что поле username существует в ответе
    } catch (error) {
      console.error('Error fetching admin data:', error);
    }
  }, []);

  const fetchBanLogs = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/banlog`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          search: banLogSearch,
          limit: 10,
          offset: (banLogPage - 1) * 10,
          dateFrom: banLogDateFrom,
          dateTo: banLogDateTo,
          timeFrom: banLogTimeFrom,
          timeTo: banLogTimeTo
        }
      });
      setBanLogs(response.data.logs);
      setBanLogTotalPages(Math.ceil(response.data.totalCount / 10));
    } catch (error) {
      console.error('Error fetching ban logs:', error);
    }
  }, [banLogSearch, banLogPage, banLogDateFrom, banLogDateTo, banLogTimeFrom, banLogTimeTo]);
  
  const fetchParkCarLogs = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/parkcarlog`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          search: parkCarLogSearch,
          limit: 10,
          offset: (parkCarLogPage - 1) * 10,
          dateFrom: parkCarLogDateFrom,
          dateTo: parkCarLogDateTo,
          timeFrom: parkCarLogTimeFrom,
          timeTo: parkCarLogTimeTo
        }
      });
      setParkCarLogs(response.data.logs);
      setParkCarLogTotalPages(Math.ceil(response.data.totalCount / 10));
    } catch (error) {
      console.error('Error fetching park car logs:', error);
    }
  }, [parkCarLogSearch, parkCarLogPage, parkCarLogDateFrom, parkCarLogDateTo, parkCarLogTimeFrom, parkCarLogTimeTo]);  

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/is-admin`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.data.isAdmin) {
          navigate('/login');
        } else {
          setIsAdminLevelSufficient(response.data.adminLevel >= 6);
          logAction('Enter Admin Page', 'Admin entered admin dashboard');
          fetchAdminData();
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/login');
      }
    };

    checkAdminStatus();
    fetchServerInfo();
    fetchAdminInfo();
    fetchPlayers();
    fetchLogs();
    fetchPortalLogs();
    fetchLabels();
    fetchCurrentPlayers();

    return () => {
      logAction('Leave Admin Page', 'Admin left admin dashboard');
    };
  }, [navigate, fetchPlayers, fetchAdminInfo, fetchLogs, fetchPortalLogs, fetchLabels, fetchCurrentPlayers, fetchAdminData]);

  const fetchServerInfo = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/server-info`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setServerInfo(response.data);
    } catch (error) {
      console.error('Error fetching server info:', error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    fetchPlayers();
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchAdminInfo();
  };

  const handleLogFilter = (e) => {
    e.preventDefault();
    setLogPage(1);
    fetchLogs();
  };

  const handlePortalLogFilter = (e) => {
    e.preventDefault();
    setPortalLogPage(1);
    fetchPortalLogs();
  };

  const handleLabelFilter = (e) => {
    e.preventDefault();
    setLabelPage(1);
    fetchLabels();
  };

  const handleBanLogFilter = (e) => {
    e.preventDefault();
    setBanLogPage(1);
    fetchBanLogs();
  };
  
  const handleParkCarLogFilter = (e) => {
    e.preventDefault();
    setParkCarLogPage(1);
    fetchParkCarLogs();
  };  

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleLogNextPage = () => {
    if (logPage < logTotalPages) {
      setLogPage(logPage + 1);
    }
  };

  const handleLogPreviousPage = () => {
    if (logPage > 1) {
      setLogPage(logPage - 1);
    }
  };

  const handlePortalLogNextPage = () => {
    if (portalLogPage < portalLogTotalPages) {
      setPortalLogPage(portalLogPage + 1);
    }
  };

  const handlePortalLogPreviousPage = () => {
    if (portalLogPage > 1) {
      setPortalLogPage(portalLogPage - 1);
    }
  };

  const handleLabelNextPage = () => {
    if (labelPage < labelTotalPages) {
      setLabelPage(labelPage + 1);
    }
  };

  const handleLabelPreviousPage = () => {
    if (labelPage > 1) {
      setLabelPage(labelPage - 1);
    }
  };

  const toggleTab = (tab) => {
    setActiveTab(activeTab === tab ? null : tab);
  };

  useEffect(() => {
    fetchBanLogs();
    fetchParkCarLogs();
    fetchAdminRules();
  }, [fetchBanLogs, fetchParkCarLogs, fetchAdminRules]);  

  const handleAddLabel = async (e) => {
    e.preventDefault();
    const pawnCode = e.target.elements.pawn_code.value;
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/add-label`, { pawnCode }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLabels();
      e.target.reset();
    } catch (error) {
      console.error('Error adding label:', error);
    }
  };

  const handleDeleteLabel = async (e) => {
    e.preventDefault();
    const labelId = e.target.elements.label_id.value;
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/delete-label`, { labelId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLabels();
    } catch (error) {
      console.error('Error deleting label:', error);
    }
  };

  const updateWarning = async (adminName, column, value) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/update-admin-info`, {
        stName: adminName,
        [column]: value
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchAdminInfo();
      logAction('Update Warning', `Updated ${column} for ${adminName} to ${value}`);
    } catch (error) {
      console.error('Error updating warning:', error);
    }
  };

  const isAuthorizedToEdit = (username === 'Timofey_Lvenocek' || username === 'Alexey_Kozyrev');

  return (
    <Container>
      <Title>Панель администратора</Title>
      <WelcomeText>Привет, {username}!</WelcomeText>
      <DateTime>{currentDateTime}</DateTime>
      <Menu>
        <MenuItem onClick={() => toggleTab('server-info')}>Игроки на сервере</MenuItem>
        <MenuItem onClick={() => toggleTab('admins-online')}>Статистика администрации</MenuItem>
        <MenuItem onClick={() => toggleTab('players')}>База игроков</MenuItem>
        <MenuItem onClick={() => toggleTab('rules')}>Правила</MenuItem>
        {isAdminLevelSufficient && <MenuItem onClick={() => toggleTab('logs')}>Логи</MenuItem>}
        {isAdminLevelSufficient && <MenuItem onClick={() => toggleTab('portal-logs')}>Портальные логи</MenuItem>}
        {isAdminLevelSufficient && <MenuItem onClick={() => toggleTab('labels')}>Надписи</MenuItem>}
        <MenuItem onClick={() => toggleTab('banlog')}>Банлог</MenuItem>
        <MenuItem onClick={() => toggleTab('parkcarlog')}>Логи ШС</MenuItem>
      </Menu>
      <Section active={activeTab === 'server-info' ? true : undefined}>
        <h3>Игроки на сервере</h3>
        <p>Онлайн: {serverInfo.online_players}/{serverInfo.max_players}</p>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Ник</th>
                <th>Уровень</th>
                <th>Ping</th>
              </tr>
            </thead>
            <tbody>
              {currentPlayers.map(player => (
                <tr key={player.id} style={{ color: admins.some(admin => admin.aName === player.name) ? 'orange' : 'inherit' }}>
                  <td>{player.id}</td>
                  <td>
                    {player.name}
                    {admins.some(admin => admin.aName === player.name) && <FaCrown style={{ marginLeft: '8px', color: 'gold' }} />}
                  </td>
                  <td>{player.score}</td>
                  <td>{player.ping}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </Section>
      <Section active={activeTab === 'admins-online' ? true : undefined}>
        <h3>Статистика администрации</h3>
        <FilterForm onSubmit={handleFilter}>
          <Input 
            type="date" 
            value={dateFrom} 
            onChange={(e) => setDateFrom(e.target.value)} 
            placeholder="From Date" 
          />
          <Input 
            type="date" 
            value={dateTo} 
            onChange={(e) => setDateTo(e.target.value)} 
            placeholder="To Date" 
          />
          <Button type="submit">Фильтровать</Button>
        </FilterForm>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Nickname</th>
                <th>Level</th>
                <th>Last Login</th>
                <th>Total Time</th>
                <th>Total Recon</th>
                <th>Total Kick</th>
                <th>Total Warn</th>
                <th>Total Ban</th>
                <th>Total Red</th>
                <th>Total Report</th>
                <th>Предупреждения</th>
                <th>Выговоры</th>
              </tr>
            </thead>
            <tbody>
              {admins.map(admin => (
                <tr key={admin.aName}>
                  <td>{admin.aName}</td>
                  <td>{admin.aAdminName}</td>
                  <td>{admin.stAdmin}</td>
                  <td>{new Date(admin.lastLogin).toLocaleDateString('ru-RU')}</td>
                  <td>{admin.totalTime}</td>
                  <td>{admin.totalRecon}</td>
                  <td>{admin.totalKick}</td>
                  <td>{admin.totalWarn}</td>
                  <td>{admin.totalBan}</td>
                  <td>{admin.totalRed}</td>
                  <td>{admin.totalReport}</td>
                  <EditableCell>
                    {isAuthorizedToEdit ? (
                      <input 
                        type="number" 
                        defaultValue={admin.aPred} 
                        style={{ width: '145px' }}
                        onBlur={(e) => updateWarning(admin.aName, 'aPred', e.target.value)} 
                      />
                    ) : (
                      admin.aPred
                    )}
                  </EditableCell>
                  <EditableCell>
                    {isAuthorizedToEdit ? (
                      <input 
                        type="number" 
                        defaultValue={admin.aVigovors} 
                        style={{ width: '80px' }}
                        onBlur={(e) => updateWarning(admin.aName, 'aVigovors', e.target.value)} 
                      />
                    ) : (
                      admin.aVigovors
                    )}
                  </EditableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </Section>
      <Section active={activeTab === 'players' ? true : undefined}>
        <h3>База игроков</h3>
        <form onSubmit={handleSearch}>
          <Input 
            type="text" 
            value={search} 
            onChange={(e) => setSearch(e.target.value)} 
            placeholder="Поиск по нику" 
          />
          <Button type="submit">Искать</Button>
        </form>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Наличные</th>
                <th>Банк</th>
                <th>Накопления</th>
                <th>Бонусы</th>
                <th>Штрафы</th>
                <th>Уровень</th>
                <th>Скин</th>
                <th>Дата регистрации</th>
                <th>VIP</th>
                <th>Военник</th>
                <th>Car License</th>
                <th>Gold License</th>
                <th>Boat License</th>
                <th>Fishing License</th>
                <th>Gun License</th>
              </tr>
            </thead>
            <tbody>
              {players.map(player => (
                <tr key={player.stID}>
                  <td>{player.stID}</td>
                  <td>{player.stName}</td>
                  <td>{player.stBabki}</td>
                  <td>{player.stBank}</td>
                  <td>{player.stNakop}</td>
                  <td>{player.stBonus}</td>
                  <td>{player.stShtraf}</td>
                  <td>{player.stLevel}</td>
                  <td>{player.stModel}</td>
                  <td>{formatDate(player.stDataReg)}</td>
                  <td>{player.stVip}</td>
                  <td>{player.stVoennik}</td>
                  <td>{player.stCarLic}</td>
                  <td>{player.stGoldLic}</td>
                  <td>{player.stBoatLic}</td>
                  <td>{player.stFishLic}</td>
                  <td>{player.stGunLic}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        <Pagination>
          <Button onClick={handlePreviousPage} disabled={page === 1}>Назад</Button>
          <Button onClick={handleNextPage} disabled={page === totalPages}>Далее</Button>
        </Pagination>
      </Section>
      <Section active={activeTab === 'rules' ? true : undefined}>
        <h3>Правила администрации</h3>
        {isEditing ? (
          <>
            <Editor
              apiKey="va8ubobz5121m2v94q5hdd204u2vfg7b2e08voy4bi2jf74y"
              value={adminRules}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                  'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                  'textcolor'
                ],
                toolbar: `undo redo | formatselect | bold italic forecolor backcolor | 
                          alignleft aligncenter alignright alignjustify | 
                          bullist numlist outdent indent | removeformat | help | table`,
                content_style: `
                  body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
                  table { border-collapse: collapse; width: 100%; }
                  th, td { border: 1px solid #ddd; padding: 8px; }
                  th { background-color: #f2f2f2; text-align: left; }
                `
              }}
              onEditorChange={(content) => setAdminRules(content)}
            />
            <Button onClick={saveAdminRules}>Сохранить</Button>
            <Button onClick={() => setIsEditing(false)}>Отмена</Button>
          </>
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: adminRules }} />
            {isAuthorizedToEdit && (
              <Button onClick={() => setIsEditing(true)}>Редактировать</Button>
            )}
          </>
        )}
      </Section>
      {isAdminLevelSufficient && (
        <Section active={activeTab === 'logs' ? true : undefined}>
          <h3>Логи</h3>
          <FilterForm onSubmit={handleLogFilter}>
            <Input 
              type="text" 
              value={logSearch} 
              onChange={(e) => setLogSearch(e.target.value)} 
              placeholder="Поиск в логах" 
            />
            <Input 
              type="date" 
              value={logDateFrom} 
              onChange={(e) => setLogDateFrom(e.target.value)} 
              placeholder="From Date" 
            />
            <Input 
              type="date" 
              value={logDateTo} 
              onChange={(e) => setLogDateTo(e.target.value)} 
              placeholder="To Date" 
            />
            <select value={logType} onChange={(e) => setLogType(e.target.value)}>
            <option value="AdEngine">AdEngine</option>
            <option value="AkkLog">AkkLog</option>
            <option value="AgivegunLog">AgivegunLog</option>
            <option value="ASkinLog">ASkinLog</option>
            <option value="AuctionLog">AuctionLog</option>
            <option value="AutoLog">AutoLog</option>
            <option value="BabkiLog">BabkiLog</option>
            <option value="BanLog">BanLog</option>
            <option value="BaseLog">BaseLog</option>
            <option value="BizLog">BizLog</option>
            <option value="BonusLog">BonusLog</option>
            <option value="ChatLog">ChatLog</option>
            <option value="ChatMobileLog">ChatMobileLog</option>
            <option value="CheatsLog">CheatsLog</option>
            <option value="CheckLog">CheckLog</option>
            <option value="ConnectLog">ConnectLog</option>
            <option value="DisconnectLog">DisconnectLog</option>
            <option value="DriveByLog">DriveByLog</option>
            <option value="FamilyLog">FamilyLog</option>
            <option value="FixvehLog">FixvehLog</option>
            <option value="FurnitureLog">FurnitureLog</option>
            <option value="GarbageLog">GarbageLog</option>
            <option value="GateLog">GateLog</option>
            <option value="GetcarLog">GetcarLog</option>
            <option value="GethereLog">GethereLog</option>
            <option value="GivegunLog">GivegunLog</option>
            <option value="GotoLog">GotoLog</option>
            <option value="HouseLog">HouseLog</option>
            <option value="JobLog">JobLog</option>
            <option value="KazinoLog">KazinoLog</option>
            <option value="KickLog">KickLog</option>
            <option value="KillLog">KillLog</option>
            <option value="KlychLog">KlychLog</option>
            <option value="LeaderLog">LeaderLog</option>
            <option value="LotteryLog">LotteryLog</option>
            <option value="MakeLog">MakeLog</option>
            <option value="NakopLog">NakopLog</option>
            <option value="NickLog">NickLog</option>
            <option value="NomerLog">NomerLog</option>
            <option value="PerevodLog">PerevodLog</option>
            <option value="ProdLog">ProdLog</option>
            <option value="PromoLog">PromoLog</option>
            <option value="QuestLog">QuestLog</option>
            <option value="RazboyLog">RazboyLog</option>
            <option value="ReconLog">ReconLog</option>
            <option value="RouletteLog">RouletteLog</option>
            <option value="RussianRol">RussianRol</option>
            <option value="SetfuelLog">SetfuelLog</option>
            <option value="SethpLog">SethpLog</option>
            <option value="ShopotLog">ShopotLog</option>
            <option value="ShtrafcarLog">ShtrafcarLog</option>
            <option value="SkinLog">SkinLog</option>
            <option value="SletLog">SletLog</option>
            <option value="SmsLog">SmsLog</option>
            <option value="StorageLog">StorageLog</option>
            <option value="TrunkLog">TrunkLog</option>
            <option value="TunLog">TunLog</option>
            <option value="TuningLog">TuningLog</option>
            <option value="UgonLog">UgonLog</option>
            <option value="WeeklyLotLog">WeeklyLotLog</option>
            <option value="ZakazLog">ZakazLog</option>
            <option value="AdsLog">AdsLog</option>
            </select>
            <Button type="submit">Filter</Button>
          </FilterForm>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Timestamp</th>
                  <th>Type</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {logs.map(log => (
                  <tr key={log.ID}>
                    <td>{log.ID}</td>
                    <td>{new Date(log.timestamp).toLocaleString('ru-RU')}</td>
                    <td>{log.log_type}</td>
                    <td>{log.log_message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          <Pagination>
            <Button onClick={handleLogPreviousPage} disabled={logPage === 1}>Назад</Button>
            <Button onClick={handleLogNextPage} disabled={logPage === logTotalPages}>Далее</Button>
          </Pagination>
        </Section>
      )}
      {isAdminLevelSufficient && (
        <Section active={activeTab === 'portal-logs' ? true : undefined}>
          <h3>Портальные логи</h3>
          <FilterForm onSubmit={handlePortalLogFilter}>
            <Input 
              type="text" 
              value={portalLogSearch} 
              onChange={(e) => setPortalLogSearch(e.target.value)} 
              placeholder="Поиск в портальных логах" 
            />
            <Input 
              type="date" 
              value={portalLogDateFrom} 
              onChange={(e) => setPortalLogDateFrom(e.target.value)} 
              placeholder="From Date" 
            />
            <Input 
              type="date" 
              value={portalLogDateTo} 
              onChange={(e) => setPortalLogDateTo(e.target.value)} 
              placeholder="To Date" 
            />
            <Button type="submit">Filter</Button>
          </FilterForm>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Timestamp</th>
                  <th>Username</th>
                  <th>Action</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {portalLogs.map(log => (
                  <tr key={log.id}>
                    <td>{log.id}</td>
                    <td>{new Date(log.timestamp).toLocaleString('ru-RU')}</td>
                    <td>{log.username}</td>
                    <td>{log.action}</td>
                    <td>{log.details}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          <Pagination>
            <Button onClick={handlePortalLogPreviousPage} disabled={portalLogPage === 1}>Назад</Button>
            <Button onClick={handlePortalLogNextPage} disabled={portalLogPage === portalLogTotalPages}>Далее</Button>
          </Pagination>
        </Section>
      )}
      {isAdminLevelSufficient && (
        <Section active={activeTab === 'labels' ? true : undefined}>
          <h3>Надписи для Тимофея</h3>
          <div>
            <div>
              <Button onClick={() => setActiveSubTab('add-label')}>Добавление</Button>
              <Button onClick={() => setActiveSubTab('delete-label')}>Удаление</Button>
              <Button onClick={() => setActiveSubTab('view-labels')}>Просмотр надписей</Button>
            </div>
            <div style={{ display: activeSubTab === 'add-label' ? 'block' : 'none' }}>
              <form onSubmit={handleAddLabel}>
                <label htmlFor="pawn_code">PAWN Code:</label><br/>
                <textarea id="pawn_code" name="pawn_code" rows="10" cols="50" required /><br/><br/>
                <input type="submit" value="Add Label" />
              </form>
            </div>
            <div style={{ display: activeSubTab === 'delete-label' ? 'block' : 'none' }}>
              <form onSubmit={handleDeleteLabel}>
                <label htmlFor="label_id">ID надписи:</label><br/>
                <Input type="number" id="label_id" name="label_id" required /><br/><br/>
                <input type="submit" value="Delete Label" />
              </form>
            </div>
            <div style={{ display: activeSubTab === 'view-labels' ? 'block' : 'none' }}>
              <form onSubmit={handleLabelFilter}>
                <label htmlFor="filter_text">Фильтровать по тексту:</label><br/>
                <Input type="text" id="filter_text" name="filter_text" value={labelSearch} onChange={(e) => setLabelSearch(e.target.value)} /><br/><br/>
                <label htmlFor="filter_vehicle_id">Фильтровать по vehicle ID:</label><br/>
                <Input type="text" id="filter_vehicle_id" name="filter_vehicle_id" value={vehicleSearch} onChange={(e) => setVehicleSearch(e.target.value)} /><br/><br/>
                <Button type="submit">Filter</Button>
              </form>
              <TableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Model</th>
                      <th>Create X</th>
                      <th>Create Y</th>
                      <th>Create Z</th>
                      <th>Create RX</th>
                      <th>Create RY</th>
                      <th>Create RZ</th>
                      <th>Attach X</th>
                      <th>Attach Y</th>
                      <th>Attach Z</th>
                      <th>Attach RX</th>
                      <th>Attach RY</th>
                      <th>Attach RZ</th>
                      <th>Vehicle ID</th>
                      <th>Material Model</th>
                      <th>Material Library</th>
                      <th>Material Name</th>
                      <th>Text</th>
                      <th>Text Size</th>
                      <th>Text Font</th>
                      <th>Text Color</th>
                      <th>Text Align</th>
                      <th>Text Style</th>
                      <th>Text Outline</th>
                      <th>Text Shadow</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labels.map(label => (
                      <tr key={label.id}>
                        <td>{label.id}</td>
                        <td>{label.model}</td>
                        <td>{label.create_x}</td>
                        <td>{label.create_y}</td>
                        <td>{label.create_z}</td>
                        <td>{label.create_rx}</td>
                        <td>{label.create_ry}</td>
                        <td>{label.create_rz}</td>
                        <td>{label.attach_x}</td>
                        <td>{label.attach_y}</td>
                        <td>{label.attach_z}</td>
                        <td>{label.attach_rx}</td>
                        <td>{label.attach_ry}</td>
                        <td>{label.attach_rz}</td>
                        <td>{label.vehicle_id}</td>
                        <td>{label.material_model}</td>
                        <td>{label.material_lib}</td>
                        <td>{label.material_name}</td>
                        <td>{label.text}</td>
                        <td>{label.text_size}</td>
                        <td>{label.text_font}</td>
                        <td>{label.text_color}</td>
                        <td>{label.text_align}</td>
                        <td>{label.text_style}</td>
                        <td>{label.text_outline}</td>
                        <td>{label.text_shadow}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableWrapper>
              <Pagination>
                <Button onClick={handleLabelPreviousPage} disabled={labelPage === 1}>Назад</Button>
                <Button onClick={handleLabelNextPage} disabled={labelPage === labelTotalPages}>Далее</Button>
              </Pagination>
            </div>
          </div>
        </Section>
      )}
        <Section active={activeTab === 'banlog' ? true : undefined}>
          <h3>Банлог</h3>
          <FilterForm onSubmit={handleBanLogFilter}>
            <Input 
              type="text" 
              value={banLogSearch} 
              onChange={(e) => setBanLogSearch(e.target.value)} 
              placeholder="Поиск в банлогах" 
            />
            <Input 
              type="date" 
              value={banLogDateFrom} 
              onChange={(e) => setBanLogDateFrom(e.target.value)} 
              placeholder="From Date" 
            />
            <Input 
              type="date" 
              value={banLogDateTo} 
              onChange={(e) => setBanLogDateTo(e.target.value)} 
              placeholder="To Date" 
            />
            <Input 
              type="time" 
              value={banLogTimeFrom} 
              onChange={(e) => setBanLogTimeFrom(e.target.value)} 
              placeholder="From Time" 
            />
            <Input 
              type="time" 
              value={banLogTimeTo} 
              onChange={(e) => setBanLogTimeTo(e.target.value)} 
              placeholder="To Time" 
            />
            <Button type="submit">Filter</Button>
          </FilterForm>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Timestamp</th>
                  <th>Admin Name</th>
                  <th>Player Name</th>
                  <th>Ban Time</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {banLogs.map(log => (
                  <tr key={log.bilID}>
                    <td>{log.bilID}</td>
                    <td>{log.bilDate}</td>
                    <td>{log.bilOwner}</td>
                    <td>{log.bilName}</td>
                    <td>{log.bilTime}</td>
                    <td>{log.bilInfo}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          <Pagination>
            <Button onClick={() => setBanLogPage(banLogPage - 1)} disabled={banLogPage === 1}>Назад</Button>
            <Button onClick={() => setBanLogPage(banLogPage + 1)} disabled={banLogPage === banLogTotalPages}>Далее</Button>
          </Pagination>
        </Section>
        <Section active={activeTab === 'parkcarlog' ? true : undefined}>
          <h3>Логи ШС</h3>
          <FilterForm onSubmit={handleParkCarLogFilter}>
            <Input 
              type="text" 
              value={parkCarLogSearch} 
              onChange={(e) => setParkCarLogSearch(e.target.value)} 
              placeholder="Поиск в логах ШС" 
            />
            <Input 
              type="date" 
              value={parkCarLogDateFrom} 
              onChange={(e) => setParkCarLogDateFrom(e.target.value)} 
              placeholder="From Date" 
            />
            <Input 
              type="date" 
              value={parkCarLogDateTo} 
              onChange={(e) => setParkCarLogDateTo(e.target.value)} 
              placeholder="To Date" 
            />
            <Input 
              type="time" 
              value={parkCarLogTimeFrom} 
              onChange={(e) => setParkCarLogTimeFrom(e.target.value)} 
              placeholder="From Time" 
            />
            <Input 
              type="time" 
              value={parkCarLogTimeTo} 
              onChange={(e) => setParkCarLogTimeTo(e.target.value)} 
              placeholder="To Time" 
            />
            <Button type="submit">Filter</Button>
          </FilterForm>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Timestamp</th>
                  <th>Admin Name</th>
                  <th>Player Name</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {parkCarLogs.map(log => (
                  <tr key={log.pclID}>
                    <td>{log.pclID}</td>
                    <td>{log.pclDate}</td>
                    <td>{log.pclName}</td>
                    <td>{log.pclCar}</td>
                    <td>{log.pclInfo}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          <Pagination>
            <Button onClick={() => setParkCarLogPage(parkCarLogPage - 1)} disabled={parkCarLogPage === 1}>Назад</Button>
            <Button onClick={() => setParkCarLogPage(parkCarLogPage + 1)} disabled={parkCarLogPage === parkCarLogTotalPages}>Далее</Button>
          </Pagination>
        </Section>
    </Container>
  );
};

export default Admin;
